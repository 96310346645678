<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';

/**
 * Orders component
 */

export default {
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            picked: "",
            daterange: "",
          loading: true,
            title: "Historique des alertes",
            items: [
                {
                    text: "Gestion d'alerts",
                    href: "/",
                },
                {
                    text: "Alertes envoyés",
                    active: true,
                },
            ],
            alerts: {},
            alertData: {},
            alertsLus: [],
            reqError: null,
        };
    },
    computed: {
        /**
         * Total no. of records
         */
    },
    mounted() {
        this.getAlertsList();
        const alertsLusFromLocalStorage = localStorage.getItem('alertsLus');

        if (alertsLusFromLocalStorage) {
            this.alertsLus = JSON.parse(alertsLusFromLocalStorage);
        }
    },
    methods: {
        ...reqMethods,
        searchFilter(e) {
            const searchStr = e.target.value;
            this.alertData = this.alerts.filter((alert) => {
                return (
                    alert.title.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.thematic.name.toLowerCase().includes(searchStr.toLowerCase()) ||
                    alert.short_code.toLowerCase().includes(searchStr.toLowerCase())
                );
            });
        },
        dateFilter() {
            const dateSearh = this.picked;
            console.log(dateSearh);
            this.alertData = this.alerts.filter((alert) => {
                return (
                    alert.created_at.split('T')[0].includes(dateSearh)
                );
            });
        },
        getAlertsList() {
            const that = this;
          this.loading = true;
            this.getRequest('/alert/alert/alertcenter/')
                .then(
                    function (response) {
                        that.alerts = response.data;
                        that.alertData = response.data;
                    },
                    function (error) {
                        that.reqError = error;
                    }
                ) .finally(() => {
              that.loading = false;
            });
        },
        afficherDetails(alert) {
            if (!this.alertsLus.includes(alert.short_code)) {
                this.alertsLus.push(alert.short_code);
                localStorage.setItem('alertsLus', JSON.stringify(this.alertsLus));
            }

            this.$router.push({ path: `/alertDelail/${alert.id}` });
        }

    },
};
</script>

<style scoped>
.alerte-non-lue {
    background-color: #f2f2f2;
  }
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh
}
</style>

<template >
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-3">Liste des alertes</h4>
                        <div class="row justify-content-between">
                            <div class="col-md-5">
                                <div class="form-group mt-3 mb-0">
                                    <div class="search-box me-2">
                                        <div class="position-relative">
                                            <input type="text" class="form-control bg-soft bg-info border-0"
                                                placeholder="Rechercher..." @input="searchFilter($event)" />

                                            <i class="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-md-6 justify-content-end">
                                <div class="form-group col-md-4 mt-3 mb-0">
                                    <input type="date" placeholder="Date" v-model="picked" class="form-control">
                                </div>
                                <div class="mt-3 col-md-4 mb-0">
                                    <button class="btn btn-info" @click="dateFilter">
                                        <i class="bx bx-slider align-middle me-1"></i>
                                        Filtrer
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive mt-3"  v-if="!loading">
                            <table class="table table-nowrap table-centered mb-0 align-middle">
                                <thead class="table-light">
                                    <tr>
                                        <th>Référence</th>
                                        <th>Titre</th>
                                        <th>Thématique</th>
                                        <th scope="col">Urgence</th>
                                        <th scope="col">Etat</th>
                                        <th scope="col">Vues</th>
                                        <th scope="col">Likes</th>
                                        <th scope="col">Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody v-if="Object.keys(alertData).length > 0">
                                    <tr v-for="alert in alertData" :key='alert.id' :class="{ 'alerte-non-lue': !alertsLus.includes(alert.short_code) }">
                                        <td> {{ alert.short_code }} </td>
                                        <td> {{ alert.title }} </td>
                                        <td> {{ alert.thematic.name }} </td>
                                        <td v-if="alert.urgency_level === 0">
                                            <span class="badge badge-pill badge-soft-success font-size-11">
                                               Normal
                                            </span>
                                        </td>
                                        <td v-else-if="alert.urgency_level === 1">
                                            <span class="badge badge-pill badge-soft-warning font-size-11">
                                               Urgent
                                            </span>
                                        </td>
                                      <td v-else>
                                            <span class="badge badge-pill badge-soft-success font-size-11">
                                               Normal
                                            </span>
                                      </td>

                                        <td v-if="alert.send == true"><span class="text-success">Publiée</span></td>
                                        <td v-if="alert.send == false"><span class="text-danger">Non Publiée</span></td>
                                        <td><span class="badge bg-success rounded-pill"> {{ alert.views }} </span></td> 
                                        <td> <span class="badge bg-danger rounded-pill"> {{ alert.likes }} </span></td> 
                                        <td> {{ alert.created_at.split('T')[0] }} </td>
                                        <td>
                                            <button @click="afficherDetails(alert)"
                                                class="btn btn-info btn-sm me-2 w-xs">
                                                Détails
                                            </button>
                                            <button @click="$router.push({ path: `/alertReplies/${alert.id}` })"
                                                class="btn btn-success me-2 btn-sm w-xs">
                                                Reponses
                                                <span class="badge bg-danger rounded-pill"> {{ alert.non_lus }} </span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="Object.keys(alertData).length == 0" class="text-center">
                                <h4>Aucune Alerte</h4>
                            </div>
                        </div>
                      <div v-else class="loading-container">
                        <div class="text-center">
                          <i class="bx bx-loader bx-spin" style="font-size: 5em;"></i>
                          <h1>Chargement</h1>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
